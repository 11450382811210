import React from 'react';
import Event from './Event';
import {Aug2022,Holi2022,Nov2022,independencedayWishes, Oct2023, Aug2024} from './imports';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './upcomingevent.css';

const AllEvents = () => {
  return (
    <div>
     <div className="gpt3__event section__padding" id="home">
        <div className="gpt3__event-content">
       <h1 className="gradient__text">Our Events</h1>
      </div>
    </div>
    <Carousel useKeyboardArrows>
    
    <div className="gpt3__event section__padding" id="home">
      <div className="gpt3__event-content">
       <h1 className="gradient__text">
       BollywoodKiDhun star Shwayta Sharma, got an opportunity to participate in FIDO-ROGERS DIWALI commercial for Diwali Week. We feel very proud and congratulate her on this milestone achievement..</h1>
        <p></p>
        <p></p>
        <p></p>
      </div>

    <div className="gpt3__event-image">
          <iframe width="760" height="515" src="https://www.youtube.com/embed/02Lfj6Y5Nl0?si=NG5O42x0cnSiVaDH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>

      <div className="gpt3__event section__padding" id="home">
      <div className="gpt3__event-content">
       <h1 className="gradient__text">
         We finished our event on the occassion of 78th Independence Day of India, Seating is Limited. Please checkout details.</h1>
        <p></p>
        <p></p>
        <p></p>
      </div>

    <div className="gpt3__event-image">
          <img src={Aug2024} />
        </div>
      </div>


    <div className="gpt3__event section__padding" id="home">
        <div className="gpt3__event-content">
       <h1 className="gradient__text">
         We recently finished our event on the occasion of Diwali that was much appreciated by all the attendees </h1>
        <p></p>
        <p></p>
        <p></p>
      </div>

    <div className="gpt3__event-image">
          <img src={Oct2023} />
        </div>
      </div>

     <div className="gpt3__event section__padding" id="home">
        <div className="gpt3__event-content">
       <h1 className="gradient__text">We recently completed our event Retro Reels that was a huge success, with songs featuring from the golden era of the bollywood </h1>
        <p></p>
        <p></p>
        <p></p>
      </div>

    <div className="gpt3__event-image">
          <img src={Nov2022} />
        </div>
      </div>

    <div className="gpt3__event section__padding" id="home">
        <div className="gpt3__event-content">
        <h1 className="gradient__text">Yet another successful event as a tribute to the Greatest Singer of the bollywood Kishor Da on his Birth Anniversary </h1>
        <p></p>
        <p></p>
        <p></p>
      </div>

    <div className="gpt3__event-image">
          <img src={Aug2022} />
        </div>
      </div>

     <div className="gpt3__event section__padding" id="home">
        <div className="gpt3__event-content">
        <h1 className="gradient__text">We completed our event on the occassion of Holi 2022  </h1>
        <p></p>
        <p></p>
        <p></p>
      </div>

    <div className="gpt3__event-image">
          <img src={Holi2022} />
        </div>
      </div>
    </Carousel>
</div>
  )
}

export default AllEvents
